var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import { deepmerge } from '@mui/utils';
import CXMTheme, { ThemeProvider } from 'shared/core/CXMTheme';
var useStyles = makeStyles(function (_a) {
    var _b;
    var components = _a.components;
    return deepmerge({
        root: {
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            zIndex: 2,
            width: "calc(100% - 3.2rem)",
            maxWidth: 504,
            maxHeight: "calc(100% - 3.2rem)",
        },
    }, (_b = components.CxmPopupBox) === null || _b === void 0 ? void 0 : _b.styleOverrides);
});
var PopupBox = function (props) {
    var children = props.children, className = props.className;
    var classes = useStyles(props);
    return (_jsx(Paper, __assign({ elevation: 0, square: true, role: "dialog", className: className, classes: classes }, { children: children })));
};
var ThemedPopupBox = function (props) {
    // get theme from popup props
    var themeName = props.theme;
    // popup without theme - will use the default
    if (!themeName)
        return _jsx(PopupBox, __assign({}, props));
    // themed popups
    var theme = CXMTheme.get(themeName);
    return (_jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsx(PopupBox, __assign({}, props)) })));
};
export default ThemedPopupBox;
