var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/system';
import { deepmerge } from '@mui/utils';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useApi } from 'shared/api/ApiContext';
import Countdown from 'shared/components/Countdown';
import PendingTermsPopup from 'shared/components/PendingTermsPopup';
import StoryAds from 'shared/components/StoryAds';
import PageFooter from 'shared/components/page/PageFooter';
import StatusBar from 'shared/components/page/StatusBar';
import AppMenu from 'shared/components/page/menu/appbar/Appbar';
import PageMainMenu from 'shared/components/page/menu/pageMenu/PageMainMenu';
import { useAppState } from 'shared/core/AppStateProvider';
import { Radio } from 'shared/core/Radio';
import useLayoutDimensions from 'shared/hooks/useLayoutDimensions';
import GTMManager from 'shared/utils/GTMManager';
var useStyles = makeStyles(function (_a) {
    var _b;
    var components = _a.components, palette = _a.palette, maxWidth = _a.maxWidth;
    return deepmerge({
        pageRoot: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flex: 1,
            width: '100%',
            height: '100%',
            margin: '0 auto',
            backgroundColor: palette.common.white,
            overflowY: 'hidden',
        },
        pageWrapper: {
            position: 'relative',
            display: 'flex',
            flex: 1,
            width: '100%',
            minWidth: 320,
            boxShadow: '0 0 12px 2px rgba(0,0,0,0.2)',
        },
        pageWithMaxWidth: {
            maxWidth: maxWidth,
        },
        pageBottom: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
        },
        radioIframe: {},
        pageFooter: {
            position: 'relative',
            zIndex: 0,
            width: '100%',
        },
    }, (_b = components.CxmPageWrapper) === null || _b === void 0 ? void 0 : _b.styleOverrides);
});
function PageWrapper(props) {
    var _a;
    var _this = this;
    var _b, _c, _d;
    var templateFactory = props.templateFactory;
    var _e = useState(null), state = _e[0], setState = _e[1];
    var _f = useState(false), skipPopups = _f[0], setSkipPopups = _f[1];
    // const [hasHealthWarning, setHasHealthWarning] = useState(false)
    var _g = useState(), countdownVisible = _g[0], setCountdownVisible = _g[1];
    var pageWrapper = useRef();
    var location = useLocation();
    var api = useApi();
    var _h = useLayoutDimensions(), sectionBottomHeight = _h.sectionBottomHeight, topBannerHeight = _h.topBannerHeight;
    var _j = useAppState(), settings = _j.settings, isHealthWarningStatic = _j.isHealthWarningStatic;
    var captchaId = settings === null || settings === void 0 ? void 0 : settings.captchaId;
    var isCaptchaSetup = useRef(false);
    useEffect(function () {
        if (isCaptchaSetup.current)
            return;
        if (captchaId) {
            isCaptchaSetup.current = true;
            api.connector.setupCaptcha(captchaId);
        }
    }, [captchaId]);
    var refLocation = useRef(location.pathname);
    var updatePageState = function (data) {
        var component = data.component, page = data.page, header = data.header, footer = data.footer, menu = data.menu, pendingTermsItem = data.pendingTermsItem, storyAds = data.storyAds, storyAdsPoints = data.storyAdsPoints, countdown = data.countdown;
        var newState = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (component && { component: component })), (page && { page: page })), (header && { header: header })), (footer && { footer: footer })), (menu !== undefined && { menu: menu })), (pendingTermsItem && { pendingTermsItem: pendingTermsItem })), (storyAds && { storyAds: storyAds })), (storyAdsPoints && { storyAdsPoints: storyAdsPoints })), (countdown && { countdown: countdown }));
        if (Object.keys(newState).length)
            setState(function (prevState) { return (__assign(__assign({}, prevState), newState)); });
        if (countdown && countdownVisible !== false)
            setCountdownVisible(true);
    };
    var checkTitle = function (_a) {
        var _b = _a.page, _c = _b === void 0 ? {} : _b, _d = _c.title, title = _d === void 0 ? null : _d;
        if (title) {
            var titleElem = document.getElementsByTagName('title')[0];
            var pageTitle = titleElem.getAttribute('data-prefix') + title;
            if (titleElem.innerText !== pageTitle) {
                titleElem.innerText = pageTitle;
            }
        }
    };
    useEffect(function () {
        var _a;
        var newLocation = location.pathname !== refLocation.current;
        // if (newLocation) setReady(false)
        refLocation.current = location.pathname;
        var getData = function () { return __awaiter(_this, void 0, void 0, function () {
            var request, data, container;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = api.getRoute(location).request;
                        return [4 /*yield*/, request];
                    case 1:
                        data = _a.sent();
                        updatePageState(data);
                        container = pageWrapper.current;
                        if (container && newLocation && !location.hash)
                            window.scrollTo(0, 0);
                        checkTitle(data);
                        GTMManager.pageView(location, data);
                        return [2 /*return*/];
                }
            });
        }); };
        if (!((_a = location.pathname) === null || _a === void 0 ? void 0 : _a.startsWith('/v1/')))
            getData().catch(console.error);
    }, [location]);
    var pageComponent = templateFactory(__assign({}, state));
    var _k = state || {}, menu = _k.menu, footer = _k.footer, _l = _k.page, _m = _l === void 0 ? {} : _l, _o = _m.fullWidth, fullWidth = _o === void 0 ? false : _o, pendingTermsItem = _k.pendingTermsItem, _p = _k.storyAds, storyAds = _p === void 0 ? [] : _p, storyAdsPoints = _k.storyAdsPoints, countdown = _k.countdown;
    useEffect(function () {
        var _a;
        setSkipPopups(!!((_a = state === null || state === void 0 ? void 0 : state.page) === null || _a === void 0 ? void 0 : _a.skipPopups));
        // setHasHealthWarning(!!state?.footer?.healthWarning)
    }, [state]);
    var paddingByDimensions = function () {
        var paddingTop = 0;
        var paddingBottom = 0;
        if (!countdownVisible && window.innerWidth < 600)
            paddingTop = topBannerHeight;
        if (!isHealthWarningStatic || window.innerWidth < 600)
            paddingBottom = sectionBottomHeight;
        return { paddingTop: paddingTop, paddingBottom: paddingBottom };
    };
    var classes = useStyles();
    var theme = useTheme();
    var isMenuTypeEqualTop = ((_b = theme.pageMenu) === null || _b === void 0 ? void 0 : _b.type) === 'top';
    // const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    return (_jsxs("div", __assign({ className: classNames(classes.pageRoot) }, { children: [!isMenuTypeEqualTop && _jsx(StatusBar, __assign({}, state)), !pendingTermsItem && storyAds.length === 0 && countdown && (_jsx(Countdown, __assign({}, countdown, { onClose: function () {
                    setCountdownVisible(false);
                } }))), _jsxs("div", __assign({ id: "PageWrapper", ref: pageWrapper, className: classNames(classes.pageWrapper, (_a = {}, _a[classes.pageWithMaxWidth] = !fullWidth, _a)), style: paddingByDimensions() }, { children: [pageComponent || _jsx("div", {}), _jsx(Radio, { classes: { radioIframe: classes.radioIframe } })] })), !!isMenuTypeEqualTop && _jsx(AppMenu, __assign({ currentPage: state === null || state === void 0 ? void 0 : state.page }, menu)), isHealthWarningStatic && (_jsx(PageFooter, __assign({}, footer, { classes: { root: classes.pageFooter }, hasPageMenu: !!((_c = menu === null || menu === void 0 ? void 0 : menu.main) === null || _c === void 0 ? void 0 : _c.length) }))), _jsxs("section", __assign({ id: "sectionBottom", className: classes.pageBottom }, { children: [!isMenuTypeEqualTop && _jsx(PageMainMenu, __assign({}, menu, { hasHealthWarning: !!(footer === null || footer === void 0 ? void 0 : footer.healthWarning) })), !isHealthWarningStatic && _jsx(PageFooter, __assign({}, footer, { hasPageMenu: !!((_d = menu === null || menu === void 0 ? void 0 : menu.main) === null || _d === void 0 ? void 0 : _d.length) }))] })), !skipPopups && (_jsxs(_Fragment, { children: [pendingTermsItem && _jsx(PendingTermsPopup, __assign({}, pendingTermsItem)), !pendingTermsItem && storyAds.length > 0 && _jsx(StoryAds, { ads: storyAds, points: storyAdsPoints })] }))] })));
}
export default PageWrapper;
