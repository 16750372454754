var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import LinearProgress from '@mui/material/LinearProgress';
import { Suspense, lazy } from 'react';
import { PageTemplates } from 'jticxm-constants';
var PageSignUp = lazy(function () { return import('shared/templates/abstract/PageSignUp'); });
var PageABQuiz = lazy(function () { return import('shared/templates/PageABQuiz'); });
var PageAgeConsent = lazy(function () { return import('shared/templates/PageAgeConsent'); });
var PageContact = lazy(function () { return import('shared/templates/PageContact'); });
var PageContactForm = lazy(function () { return import('shared/templates/PageContactForm'); });
var PageDeleteUser = lazy(function () { return import('shared/templates/PageDeleteUser'); });
var PageExtensionIframe = lazy(function () { return import('shared/templates/PageExtensionIframe'); });
var PageFAQ = lazy(function () { return import('shared/templates/PageFAQ'); });
var PageProducts = lazy(function () { return import('shared/templates/abstract/PageProducts'); });
var PageLegal = lazy(function () { return import('shared/templates/PageLegal'); });
var PageMessage = lazy(function () { return import('shared/templates/PageMessage'); });
var PagePackSampling = lazy(function () { return import('shared/templates/PagePackSampling'); });
var PageResetPassword = lazy(function () { return import('shared/templates/PageResetPassword'); });
var PageRewardShop = lazy(function () { return import('shared/templates/PageRewardShop'); });
var PageRewardShopCheckout = lazy(function () { return import('shared/templates/PageRewardShopCheckout'); });
var PageSandbox = lazy(function () { return import('shared/templates/PageSandbox'); });
var PageMusicLEPIntro = lazy(function () { return import('shared/templates/abstract/PageLEPIntro'); });
var PageMusicLEPShop = lazy(function () { return import('shared/templates/abstract/PageLEPShop'); });
var PageMusicLEPGame = lazy(function () { return import('shared/templates/abstract/PageLEPGame'); });
var PageNotificationPreferences = lazy(function () { return import('shared/templates/abstract/PageNotificationPreferences'); });
var PageSelfRegistrationConfirmation = lazy(function () { return import('shared/templates/abstract/PageSelfRegistrationConfirmation'); });
var PagePrePostCampaign = lazy(function () { return import('shared/templates/abstract/PagePrePostCampaign'); });
var PageProfile = lazy(function () { return import('shared/templates/abstract/PageProfile'); });
var PagePromoStandardModular = lazy(function () { return import('shared/templates/PagePromoStandardModular'); });
var PageTemplateError = lazy(function () { return import('shared/templates/PageTemplateError'); });
var PageTradePromotion = lazy(function () { return import('shared/templates/PageTradePromotion'); });
var PageTradePromotionVerify = lazy(function () { return import('shared/templates/PageTradePromotionVerify'); });
var PageXmas = lazy(function () { return import('shared/templates/PageXmas'); });
var PageCodeScanner = lazy(function () { return import('shared/templates/abstract/PageCodeScanner'); });
var PageCommunity = lazy(function () { return import('shared/templates/abstract/PageCommunity'); });
var PageJamCommunity = lazy(function () { return import('shared/templates/abstract/PageJamCommunity'); });
var PageTerms = lazy(function () { return import('shared/templates/abstract/PageTerms'); });
var PopupAddress = lazy(function () { return import('shared/templates/PopupAddress'); });
var PopupBasic = lazy(function () { return import('shared/templates/PopupBasic'); });
var PopupFlippable = lazy(function () { return import('shared/templates/PopupFlippable'); });
var PopupBrandPreferences = lazy(function () { return import('shared/templates/PopupBrandPreferences'); });
var PopupCode = lazy(function () { return import('shared/templates/PopupCode'); });
var PopupCommunity = lazy(function () { return import('shared/templates/PopupCommunity'); });
var PopupDigitalId = lazy(function () { return import('shared/templates/PopupDigitalId'); });
var PopupDummy = lazy(function () { return import('shared/templates/PopupDummy'); });
var PopupLevelUpgrade = lazy(function () { return import('shared/templates/PopupLevelUpgrade'); });
var PopupProfileForm = lazy(function () { return import('shared/templates/PopupProfileForm'); });
var PopupPromoCode = lazy(function () { return import('shared/templates/PopupPromoCode'); });
var PopupQuiz = lazy(function () { return import('shared/templates/abstract/PopupQuiz'); });
var PopupSurvey = lazy(function () { return import('shared/templates/abstract/PopupSurvey'); });
var PopupPickPrizeVariant = lazy(function () { return import('shared/templates/PopupPickPrizeVariant'); });
export default function templateFactory(state) {
    var _a;
    var component = (state || {}).component;
    if (!component)
        return null;
    /**
     * Regular pages
     */
    switch (component) {
        case PageTemplates.PT_AB_QUIZ:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageABQuiz, __assign({}, state)) })));
        case PageTemplates.PT_AGE_CONSENT:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageAgeConsent, __assign({}, state)) })));
        case PageTemplates.PT_CODE_SCANNER:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageCodeScanner, __assign({}, state)) })));
        case PageTemplates.PT_SIGN_UP:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageSignUp, __assign({}, state)) })));
        case PageTemplates.PT_CONTACT:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageContact, __assign({}, state)) })));
        case PageTemplates.PT_CONTACT_FORM:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageContactForm, __assign({}, state)) })));
        case PageTemplates.PT_DELETE_USER:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageDeleteUser, __assign({}, state)) })));
        case PageTemplates.PT_EXTENSION_IFRAME:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageExtensionIframe, __assign({}, state)) })));
        case PageTemplates.PT_FAQ:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageFAQ, __assign({}, state)) })));
        case PageTemplates.PT_LEGAL:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageLegal, __assign({}, state)) })));
        case PageTemplates.PT_MESSAGE:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageMessage, __assign({}, state)) })));
        case PageTemplates.PT_PACK_SAMPLING:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PagePackSampling, __assign({}, state)) })));
        case PageTemplates.PT_TRADE_PROMOTION:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageTradePromotion, __assign({}, state)) })));
        case PageTemplates.PT_TERMS:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageTerms, __assign({}, state)) })));
        case PageTemplates.PT_TRADE_PROMOTION_VERIFY:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageTradePromotionVerify, __assign({}, state)) })));
        case PageTemplates.PT_RESET_PASSWORD:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageResetPassword, __assign({}, state)) })));
        case PageTemplates.PT_REWARD_SHOP:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageRewardShop, __assign({}, state)) })));
        case PageTemplates.PT_REWARD_SHOP_CHECKOUT:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageRewardShopCheckout, __assign({}, state)) })));
        case PageTemplates.PT_SANDBOX:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageSandbox, __assign({}, state)) })));
        case PageTemplates.PT_XMAS:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageXmas, __assign({}, state)) })));
        case PageTemplates.PT_PRE_POST_CAMPAIGN:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PagePrePostCampaign, __assign({}, state)) })));
        case PageTemplates.PT_PRODUCTS:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageProducts, __assign({}, state)) })));
        case PageTemplates.PT_PROFILE:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageProfile, __assign({}, state)) })));
        case PageTemplates.PT_PROMO_STANDARD_MODULAR:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PagePromoStandardModular, __assign({}, state)) })));
        case PageTemplates.PT_MUSIC_LEP_GAME:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageMusicLEPGame, __assign({}, state)) })));
        case PageTemplates.PT_MUSIC_LEP_SHOP:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageMusicLEPShop, __assign({}, state)) })));
        case PageTemplates.PT_MUSIC_LEP:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageMusicLEPIntro, __assign({}, state)) })));
        case PageTemplates.PT_COMMUNITY:
            if ((_a = state.page) === null || _a === void 0 ? void 0 : _a.isJamCommunityPage) {
                return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageJamCommunity, __assign({}, state)) })));
            }
            else {
                return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageCommunity, __assign({}, state)) })));
            }
        case PageTemplates.PT_NOTIFICATION_PREFERENCES:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageNotificationPreferences, __assign({}, state)) })));
        case PageTemplates.PT_SELF_REGISTRATION_CONFIRMATION:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageSelfRegistrationConfirmation, __assign({}, state)) })));
        /**
         * Popups
         */
        case PageTemplates.PP_SURVEY:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PopupSurvey, __assign({}, state)) })));
        case PageTemplates.PP_PROFILE_FORM:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PopupProfileForm, __assign({}, state)) })));
        case PageTemplates.PP_BRAND_PREFERENCES:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PopupBrandPreferences, __assign({}, state)) })));
        case PageTemplates.PP_ADDRESS:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PopupAddress, __assign({}, state)) })));
        case PageTemplates.PP_BASIC:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PopupBasic, __assign({}, state)) })));
        case PageTemplates.PP_FLIPPABLE:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PopupFlippable, __assign({}, state)) })));
        case PageTemplates.PP_DIGITAL_ID:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PopupDigitalId, __assign({}, state)) })));
        case PageTemplates.PP_DUMMY:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PopupDummy, __assign({}, state)) })));
        case PageTemplates.PP_CODE:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PopupCode, __assign({}, state)) })));
        case PageTemplates.PP_LEVEL_UPGRADE:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PopupLevelUpgrade, __assign({}, state)) })));
        case PageTemplates.PP_PROMO_CODE:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PopupPromoCode, __assign({}, state)) })));
        case PageTemplates.PP_QUIZ:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PopupQuiz, __assign({}, state)) })));
        case PageTemplates.PP_COMMUNITY:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PopupCommunity, __assign({}, state)) })));
        case PageTemplates.PP_PICK_PRIZE_VARIANT:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PopupPickPrizeVariant, __assign({}, state)) })));
        /**
         * No matching template found
         */
        default:
            return (_jsx(Suspense, __assign({ fallback: _jsx(LinearProgress, {}) }, { children: _jsx(PageTemplateError, __assign({}, state)) })));
    }
}
