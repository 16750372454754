import { Breakpoints, Palette, alpha, darken } from '@mui/material'
import type { Components } from '@mui/material/styles'
import type { TypographyOptions } from '@mui/material/styles/createTypography'

import type { PageMainMenuStylesProps } from 'shared/components/page/menu/pageMenu/PageMainMenu'

import colors from './colors'
import themeOptions from './options'
import typographies from './typographies'

export default function createComponents(
  breakpoints: Breakpoints,
  palette: Palette,
  typography: TypographyOptions,
): Components {
  return {
    CxmAccordion: {
      styleOverrides: {
        headerRoot: {
          backgroundColor: palette.primary.main,
        },
        headerTitle: {
          position: 'relative',
          top: '.15em',
        },
        contentRoot: {
          color: palette.text.primary,
          backgroundColor: palette.background.default,
        },
      },
    },
    CxmErrorHelperText: {
      styleOverrides: {
        iconWrapper: {
          width: '1.2rem',
          height: '1.2rem',
        },
        icon: {
          fontSize: '1.4rem',
          marginTop: -2,
        },
      },
    },
    CxmLangMenu: {
      styleOverrides: {
        langSwitch: {
          color: palette.primary.main,
          fontSize: '2.4rem',
        },
        langButton: {
          fontSize: '2rem',
          fontFamily: typographies.CenturyGothic,
          '& > span': {
            position: 'relative',
            top: '.15em',
          },
        },
        currentLang: {
          fontWeight: 700,
          '& > span': {
            top: 0,
          },
        },
      },
    },
    CxmPageABQuiz: {
      defaultProps: {
        colors: {
          light: colors.white,
          dark: colors.orange,
          question: colors.white,
          resultBackground: colors.blue,
        },
        haveButtonsSameColor: true,
      },
      styleOverrides: {
        wrapper: {
          [breakpoints.up('sm')]: {
            borderRadius: 10,
            overflow: 'hidden',
          },
        },

        optionA: {
          backgroundColor: ({ lightColor }: any) => lightColor,
          color: ({ darkColor }: any) => darkColor,

          '&:after': {
            top: '15% !important',
            height: '70%',
            width: 1,
            backgroundColor: ({ darkColor }: any) => darkColor,
          },
        },
        optionB: {
          backgroundColor: ({ lightColor }: any) => lightColor,
          color: ({ darkColor }: any) => darkColor,

          '&:before': {
            top: '15% !important',
            height: '70%',
            width: 1,
            backgroundColor: ({ darkColor }: any) => darkColor,
          },
        },

        answerText: {
          fontFamily: typographies.CenturyGothic,
          fontSize: '1.6rem',
          fontWeight: 'bold',
        },

        selectorContainer: {
          overflow: 'hidden',
        },

        resultTitle: {
          color: ({ lightColor }: any) => lightColor,
        },

        resultContent: {
          color: ({ lightColor }: any) => lightColor,
        },

        resultWrapper: {
          color: ({ questionColor }: any) => questionColor,
          backgroundColor: ({ resultBackgroundColor }: any) => resultBackgroundColor,
        },

        resultPageContainedButton: {
          backgroundColor: ({ darkColor }: any) => darkColor,
          color: ({ lightColor }: any) => lightColor,
          '&:hover,&:focus': {
            backgroundColor: ({ darkColor }: any) => darkColor,
          },
        },

        resultPageOutlinedButton: {
          backgroundColor: 'none',
          color: ({ lightColor }: any) => lightColor,
          borderColor: ({ lightColor }: any) => lightColor,
        },
      },
    },
    CxmPageXmas: {
      imagesPath: `/v1/img/promotions/xmas-nas`,
      useFrames: true,
      doorGradients: [
        'linear-gradient(135deg, #e49a3b, #b56b0b)',
        'linear-gradient(135deg, #f8e04b, #c6ab02)',
        'linear-gradient(135deg, #4d99b9, #2f6b87)',
        'linear-gradient(135deg, #b2dbe5, #89acb3)',
        'linear-gradient(135deg, #b9d28e, #8fa66a)',
      ],
      styleOverrides: {
        logo: { display: 'none' },
        title: { fontFamily: typographies.CenturyGothic, color: colors.darkBrown, fontWeight: 700 },
        doorImage: {
          borderRadius: 'none',
        },
        doorIsOpening: {
          animation: 'none',
        },
        doorContents: {
          boxShadow: 'none',
          border: 'none',
          gap: '0.8rem',
          backgroundSize: '100% 99.9%', // Not cover because of Safari, breaks there.

          '& > h6': {
            color: colors.darkBrown,
            fontSize: '1.6rem',
          },
          '& > button': {
            fontSize: '1.6rem',
          },
        },
        doorOpen: {
          transform: 'rotateY(-90deg) translateX(-22px) translateY(-2px) scale(0.98)',
          '-webkit-transform': 'rotateY(-90deg) translateX(-22px) translateY(-2px) scale(0.98)',
          opacity: 1,
        },
        doorBackside: {
          opacity: 0,
          background: `linear-gradient(to left, ${darken(colors.mutedBlue, 0.2)} 0%, ${
            colors.mutedBlue
          } 100%)`,
        },
        doorBacksideOpen: {
          opacity: 1,
        },
      },
    },
    CxmPageCommunity: {
      styleOverrides: {
        logo: {
          display: 'none',
        },
        poster: {
          marginBottom: 0,
        },
      },
    },
    CxmCommunityIntro: {
      styleOverrides: {
        intro: {
          backgroundColor: colors.white,
          paddingBottom: ' 4.8rem',
          marginBottom: 0,
          marginTop: 0,
        },
      },
    },
    CxmCommunityPrizeBlock: {
      styleOverrides: {
        prizes: {
          backgroundColor: colors.white,
        },
        prizeSlideTitle: {
          fontFamily: typographies.CenturyGothic,
          color: colors.black,
          fontWeight: 700,
        },
      },
    },
    CxmCommunityGallery: {
      styleOverrides: {
        galleryTitle: {
          paddingTop: '4.8rem',
        },
        galleryLikes: {
          color: colors.black,
          fontWeight: 700,
        },
        galleryMediaAuthor: {
          color: colors.black,
          fontWeight: 700,
        },
        galleryCardBadge: {
          color: colors.orange,
        },
      },
    },
    CxmPagePromoStandardModular: {
      contentColumnWidth: 696,
      usePadding: false,
      styleOverrides: {
        logo: {
          display: ({ hideLogo }: { hideLogo: boolean }) => (hideLogo ? 'none' : 'block'),
        },
        prizeTitle: {
          color: colors.black,
        },
        contentModulePrizeBlockTitle: {
          ...typography.h2,
          color: 'currentColor', // This will make the color adapt to the theme
        },
        mainBlockTitle: {
          color: colors.black,
        },
        contentArticlePreviewCard: {
          boxShadow: '0 0 8px rgba(0, 0, 0, 0.35)',
          backgroundColor: 'transparent',
          [breakpoints.up('sm')]: {
            boxShadow: '0 0 16px rgba(0, 0, 0, 0.35)',
            width: '30rem',
          },
        },
        contentArticlePreviewCardText: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
        contentLinkTitle: {
          margin: 'auto 0 0 0',
          ...typography.h4,
          color: palette.primary.main,
          '-webkit-line-clamp': 1,
          [breakpoints.up('sm')]: {
            ...typography.h6,
            fontFamily: typographies.FullOfSpirit,
            fontWeight: 400,
          },
        },
      },
    },
    CxmPagePackSampling: {
      titleHeading: 'h3',
      subtitleHeading: 'h5',
      showSuccessTitle: false,
      styleOverrides: {
        title: {
          textAlign: 'center',
        },
        productContainer: {
          display: 'flex',
          flexDirection: 'column',
        },
        successTitle: {
          ...typography.h5,
          order: -1,
          fontFamily: typographies.CenturyGothic,
          fontWeight: 700,
          color: colors.black,
          textAlign: 'center',
        },
        productTitle: {
          ...typography.h5,
          order: -1,
          fontFamily: typographies.CenturyGothic,
          fontWeight: 700,
          color: colors.black,
          textAlign: 'center',
          paddingTop: 0,
        },
        subtitle: {
          textAlign: 'center',
          color: colors.black,
          fontWeight: 700,
          fontFamily: typographies.CenturyGothic,
        },
        addressTitle: {
          color: colors.black,
        },
      },
    },
    CxmPopupQuiz: {
      styleOverrides: {
        questionTitle: {
          fontFamily: typographies.CenturyGothic,
          fontWeight: 700,
        },
        type_radio: {
          '&.wrong-answer label.checked': {
            color: colors.red,

            '& span': {
              color: colors.red,
            },

            '&:after': {
              backgroundColor: alpha(colors.red, 0.1),
            },
          },

          '&.correct-answer label.checked': {
            color: colors.lightGreen,

            '& span': {
              color: colors.lightGreen,
            },

            '&:after': {
              backgroundColor: alpha(colors.lightGreen, 0.1),
            },
          },
        },
      },
    },
    CxmPollComponent: {
      styleOverrides: {
        pollFormWrapper: {
          backgroundColor: colors.lightBeige,
          boxShadow: '0px 0px 8px 0px #33322C1F',
          minHeight: 0,
          borderRadius: '0px',
        },
        pollButtonContainer: {
          border: 'none',
          borderRadius: '0px',
          backgroundColor: '#FFFFFF',
        },
        resultsWrapper: {
          height: 'auto',
          minHeight: 280,
          padding: '1rem 1.6rem',
          backgroundColor: 'transparent',
        },
        resultWrapper: {
          width: '100%',
          borderRadius: '0px',
          background: '#D87879',
          color: '#FFFFFF',
        },
        resultBar: {
          background: colors.secondaryRed,
        },
        pollForm: {
          width: '100%',
        },
        formRadio: {
          '& label': {
            borderRadius: 0,
            padding: '1rem 1.2rem 1rem 0',
            backgroundColor: '#FFFFFF',
            '& .MuiRadio-root': {
              color: palette.text.primary,
            },
            '&:before': {
              backgroundColor: palette.secondary.main,
            },
            '&.checked .MuiTypography-root, & .Mui-checked': {
              color: colors.white,
            },
          },
        },
        formCheckbox: {
          borderRadius: 0,
        },
      },
    },
    CxmLegalMenu: {
      openLinksInNewTab: true,
      styleOverrides: {
        footnote: {
          color: palette.text.primary,
        },
        legalMenu: {
          backgroundColor: palette.text.secondary,

          [breakpoints.up('sm')]: {
            paddingTop: '10rem',
          },

          '& > a': {
            color: colors.black,
          },
          '& > a:hover': {
            color: colors.black,
          },
        },
      },
    },
    CxmPageExtendedMenu: {
      styleOverrides: {
        menu: {
          backgroundColor: colors.lightBeige,
        },
        logo: {
          marginBottom: '3rem',
          backgroundColor: 'transparent',
          background: `url(${themeOptions.logo.url}) no-repeat center`,
          backgroundSize: 'contain',
          mask: 'none',
        },
        link: {
          fontFamily: typographies.CenturyGothic,
          fontWeight: 700,
          color: palette.text.primary,
          '&:hover': {
            color: `${palette.text.primary} !important`,
          },
        },
      },
    },
    CxmPageFooter: {
      styleOverrides: {
        warning: {
          minHeight: 80,
          textTransform: 'none',
          fontSize: '16px !important',
          borderTop: '2px solid #000',
          borderBottom: '2px solid #000',

          '&:empty': {
            display: ({ user }: { user: boolean }) => (user ? null : 'none'),
            minHeight: 0,
            padding: 0,
            borderBottom: 'none',
          },
        },
      },
    },
    CxmPageMainMenu: {
      styleOverrides: {
        menu: {
          backgroundColor: colors.darkBlue,
          borderTop: '1px solid white',
          [breakpoints.up('sm')]: {
            bottom: ({ hwStatic, hasHealthWarning }: PageMainMenuStylesProps) => {
              if (hasHealthWarning && !hwStatic) return 110
              if (!hasHealthWarning && !hwStatic) return 30
              return null
            },
          },
        },
        icon: {
          fontSize: '2rem',
          marginBottom: 5,
          color: palette.primary.contrastText,
        },
        name: {
          fontSize: '1.2rem',
          fontWeight: 700,
          color: palette.primary.contrastText,
        },
        iconExtendedOpen: {
          backgroundColor: colors.darkBlue,
        },
      },
    },
    CxmStatusBar: {
      styleOverrides: {
        topBanner: {
          background: colors.darkBlue,
          borderBottom: '1px solid white',
        },
        greeting: {
          fontFamily: typographies.CenturyGothic,
          fontWeight: 700,
          fontSize: '1.8rem',
          lineHeight: 1,
          textTransform: 'none',
          color: palette.primary.contrastText,
        },
        info: {
          color: palette.primary.contrastText,
          fontWeight: 700,
        },
        currencyIcon: {
          position: 'relative',
          top: 0.5,
        },
        level: {
          alignItems: 'center',
        },
        levelIcon: {
          position: 'relative',
          top: '-1px !important',
        },
        pointsBadge: {
          fontWeight: 700,
          fontSize: '1.2rem',
          fontFamily: typographies.CenturyGothic,
          paddingBottom: '2px',
          textTransform: 'uppercase',
          color: colors.white,
          backgroundColor: colors.red,
          borderRadius: '1em',
          padding: '3px .6em 0 .4em',
          lineHeight: 0.9,
        },
      },
    },
    CxmPage: {
      styleOverrides: {
        pageContentRoot: {
          background: `url(${themeOptions.background.url})`,
        },
      },
    },
    CxmPageWrapper: {
      styleOverrides: {
        pageRoot: {
          backgroundColor: palette.common.white,
        },
      },
    },
    CxmPageContactForm: {
      hideGoBack: true,
    },
    CxmPageLegal: {
      hideGoBack: true,
    },
    CxmProductGroupCard: {
      styleOverrides: {
        productGroupCard: {
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          zIndex: 1,
          maxWidth: 400,
          width: '100%',
          margin: '0 auto',
          overflow: 'hidden',
          color: palette.text.primary,
          background: palette.background.default,
          boxShadow: '0 0 12px 0 rgba(0, 0, 0, 0.25)',
          paddingBottom: '3.2rem',
        },
        productGroupCardWrapper: {
          position: 'relative',
          padding: '4.8rem 1.6rem',
          '&:after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            left: 0,
            bottom: 0,
            height: 'calc(100% - 220px)',
            width: '100vw',
            backgroundColor: 'currentColor',
          },
        },
        productImage: {
          width: '100%',
          aspectRatio: '1 / 1',
          maxHeight: 'none',
          height: 'auto',
          padding: '0 !important',
        },
        productDescription: {
          padding: '2.4rem 1.6rem 0',
        },
        productNumbersWrapper: {
          display: 'flex',
          margin: 'auto',
          textAlign: 'center',
          color: palette.text.primary,
        },
        productNumber: {
          minWidth: '8rem',
          padding: '.8rem 0 0',
          whiteSpace: 'nowrap',
          '& .product-number-label': {
            marginBottom: '.6rem',
          },
        },
      },
    },
    CxmSliderProduct: {
      styleOverrides: {
        product: {
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          maxWidth: 400,
          width: '100%',
          margin: '1.6rem',
          overflow: 'hidden',
          background: palette.background.default,
          boxShadow: '0px 0px 12px 0px rgba(0, 0, 0, 0.25)',
        },
        productTitle: {
          fontFamily: typographies.CenturyGothic,
          fontWeight: 700,
          marginTop: '2.4rem',
          marginBottom: '.8rem',
          padding: '0 1.6rem',
        },
        productDescription: {
          color: colors.black,
          display: 'block',
          '& p': {
            marginBottom: 0,
          },
        },
        productDescriptionNoTable: {
          paddingTop: '5.2rem',
        },
        productImage: {
          width: '100%',
          aspectRatio: '1 / 1',
          maxHeight: 'none',
          height: 'auto',
          padding: '0 !important',
        },
        productNumbersWrapper: {
          display: 'flex',
          margin: 'auto',
          textAlign: 'center',
          padding: '0 .8rem 2.4rem',
          minHeight: '8rem',
          color: palette.text.primary,
        },
        productNumber: {
          minWidth: '6rem',
          padding: '.8rem',
          whiteSpace: 'nowrap',
          '& > p:nth-child(1)': {
            marginBottom: '.6rem',
          },
        },
        productNotice: {
          color: 'inherit',
          padding: '3.2rem 1.6rem 0',
        },
      },
    },
    CxmPageLEPGame: {
      styleOverrides: {},
      postProcessor: 'nas-jam',
      basePath: '/v1/games/nas-jam',
      jamSet: 'nas-de-jam-set',
    },
    CxmStoryAds: {
      styleOverrides: {
        navigation: {
          color: colors.white,
        },
        progressValue: {
          backgroundColor: alpha(colors.white, 0.3),
        },
        progressBar: {
          backgroundColor: colors.white,
        },
        options: {
          color: colors.white,
        },
        likeButton: {
          color: colors.white,
        },
      },
    },
    CxmPageRewardShop: {
      defaultProps: {
        groupBundles: true,
      },
      styleOverrides: {
        title: {
          color: palette.text.primary,
        },
        rewardsUserWallet: {
          color: palette.text.primary,
          backgroundColor: palette.primary.contrastText,
          boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.25)',
          border: 'none',
        },
        userWalletTitle: {
          color: palette.text.primary,
          fontFamily: typographies.CenturyGothic,
          fontWeight: 900,
          '& span:nth-child(1)': {
            color: palette.secondary.dark,
          },
        },
        rewardCard: {
          display: 'flex',
          flexDirection: 'column',
          padding: '8px 8px 0px 8px',
          width: 'calc(50% - (1.6rem + 16px))',
          backgroundColor: palette.text.secondary,
          boxShadow: '0px 0px 24px 0px rgba(51, 50, 44, 0.12)',
        },
        rewardMediaWrapper: {
          flex: 1,
        },
        rewardMedia: {
          maxHeight: '365px',
          width: '100%',
          backgroundSize: 'cover !important',
        },
        rewardLikeButton: {
          color: palette.secondary.main,
        },
        storyBlockHelpButton: {
          color: palette.text.primary,
        },
        rewardCardTitle: {
          textAlign: 'center',
        },
        rewardCardPrice: {
          color: palette.text.primary,
          textAlign: 'center',
        },
        rewardLockedFlag: {
          border: 'none',
          boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.25)',
          color: palette.secondary.main,
        },
        storyBlockContained: {
          backgroundColor: palette.secondary.dark,
          boxShadow: '0px 0px 24px 0px rgba(51, 50, 44, 0.12)',
          border: 'none',
          '& h3': {
            fontFamily: typographies.CenturyGothic,
            fontWeight: 900,
            color: palette.text.secondary,
            letterSpacing: '2px',
          },
        },
        intro: {
          padding: '0.8rem 1.6rem',
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
        },
        rewardInfoBadge: {
          fontFamily: typographies.CenturyGothic,
          padding: '2px 6px 2px 6px',
        },
      },
    },
    CxmPageRewardVariantShopCheckout: {
      styleOverrides: {
        page: {},
        pageContentContainer: {
          backgroundColor: palette.primary.contrastText,
        },
        rewardCheckout: {},
        rewardMediaWrapper: {
          width: '100%',
        },
        rewardMedia: {
          width: '100%',
          height: '100%',
          maxHeight: 'initial',
          backgroundSize: 'cover !important',
        },
        rewardLikeButton: {
          color: palette.secondary.main,
          position: 'absolute',
          right: '0',
        },
        rewardCardPrice: {
          color: palette.primary.main,
        },
        rewardLockedFlag: {
          border: 'none',
          boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.25)',
          color: palette.secondary.main,
        },
        rewardTitle: {
          color: palette.text.primary,
          hyphens: 'auto',
          textAlign: 'left',
          marginBottom: '0',
          fontSize: '5rem',
        },
        rewardPrice: {
          fontSize: '2.4rem',
          textAlign: 'left',
          color: palette.text.primary,
          fontFamily: typographies.CenturyGothic,
          fontWeight: 700,
          letterSpacing: 2,
          marginBottom: '3.2rem',
        },
        rewardContainer: {
          width: '100%',
        },
        buttonContainer: {
          maxWidth: '40rem',
        },
        rewardInfoBadge: {
          fontFamily: typographies.CenturyGothic,
        },
        rewardHint: {
          maxWidth: 'calc(40rem - 3.2rem)',
          backgroundColor: alpha(colors.darkGreen, 0.1),
          marginBottom: '2.4rem',
          border: `1px solid ${alpha(colors.darkGreen, 0.2)}`,
        },
        rewardHintText: {
          '& span': {
            color: colors.darkGreen,
          },
        },
        rewardHintLink: {
          color: colors.secondaryRed,
        },

        // Variants
        colorVariantInner: {},
        colorVariantInnerActive: {
          outline: `3px solid ${colors.secondaryRed}`,
        },

        sizeVariantInner: {
          color: colors.red,
          borderColor: colors.secondaryRed,
          fontWeight: 700,
        },
        sizeVariantInnerActive: {
          color: colors.white,
          backgroundColor: colors.secondaryRed,
        },
        sizeVariantInnerDisabled: {
          background: `linear-gradient(to bottom left,
          transparent calc(50% - 1px), ${alpha(colors.secondaryRed, 0.6)} calc(50% - 1px),
          ${alpha(colors.secondaryRed, 0.6)} 50%, transparent 50%)`,
        },
      },
    },
    CxmPageWinPrizeSlide: {
      styleOverrides: {
        prizeTitle: {
          fontSize: '2.6rem',
          fontFamily: typographies.CenturyGothic,
          color: colors.black,
          fontWeight: 700,
        },
      },
    },
    CxmPageProducts: {
      styleOverrides: {
        tabsWrapper: {
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '3.2rem',
          maxWidth: 600,
          margin: 'auto',
        },
        tab: {
          textTransform: 'uppercase',
          padding: '0 0.2em',
          margin: '0 2rem',
          cursor: 'pointer',
          width: 'calc(45% - 4rem)',
          textAlign: 'center',
        },
        tabSelected: {
          borderBottom: '2px solid currentColor',
        },

        group: {
          position: 'relative',
          backgroundSize: '100vw auto',
          backgroundColor: `white !important`,
        },
        groupTitle: {
          margin: '2.2rem 0 0',
          textAlign: 'center',
        },
        groupDescription: {
          color: palette.text.primary,
          '&:not(:empty)': {
            marginTop: '2.4rem',
          },
          '& > *': {
            color: 'currentcolor',
          },
        },

        // sliderWrapper: {
        //   '&:after': {
        //     content: '""',
        //     display: 'block',
        //     position: 'absolute',
        //     left: 0,
        //     bottom: 0,
        //     height: 'calc(100% - 220px)',
        //     width: '100vw',
        //     backgroundColor: 'currentColor',
        //   },
        // },
        // slider: {
        //   position: 'relative',
        //   zIndex: 1,
        // },
        // slideContainer: {
        //   display: 'flex',
        //   justifyContent: 'center',
        // },
        // stepper: {
        //   height: 'auto',
        //   bottom: '3rem',
        // },
        // stepperDots: {
        //   color: colors.white,
        // },
        // stepperBackButton: {
        //   display: 'none',
        // },
        // stepperNextButton: {
        //   display: 'none',
        // },
      },
      themes: {
        root: {
          blue: {
            color: colors.blue,
          },
          yellow: {
            color: colors.yellow,
          },
          beige: {
            color: colors.beige,
          },
          gold: {
            color: colors.gold,
          },
        },
        title: {},
        numbers: {},
      },
      footerNoMargin: true,
      titleAfterImage: true,
      stepperPosition: 'bottom',
      numbersLabelVariant: 'subtitle2',
      numbersValueVariant: 'body2',
      productsGroupVariant: 'single-card',
    },
    CxmTeaserBlock: {
      styleOverrides: {
        teaserBlock: {
          padding: '2.4rem 0',
          justifyContent: 'space-between',
        },
        teaserBlockMedia: {
          flex: 'none',
          width: 'calc(60% - 2.4rem)',
          [breakpoints.down('sm')]: {
            width: '100%',
          },
        },
      },
    },
    CxmTabs: {
      styleOverrides: {
        tab: {
          color: palette.text.primary,
        },
      },
    },
    CxmPageSignUp: {
      styleOverrides: {
        page: {
          backgroundSize: 'auto !important',
        },
        legalMenuSignup: {
          fontSize: '2rem',
          lineHeight: 1.5,
        },
        stepInner: {
          [breakpoints.up('sm')]: {
            backgroundColor: colors.white,
            boxShadow: '0px 0px 24px 0px rgba(51, 50, 44, 0.22)',
            border: 'none',
          },
          '& h1': {
            [breakpoints.up('sm')]: {
              fontSize: '5.8rem',
            },
          },
        },
        title: {
          color: palette.text.primary,
        },
        form: {
          marginBottom: '1.6rem',
          '& .MuiFormHelperText-root': {
            color: palette.error.main, // Fixes problem with datepicker error being the wrong color
          },
        },
        breadcrumb: {
          '& .MuiMobileStepper-dot': {
            borderColor: colors.secondaryRed,
          },
          '& .MuiMobileStepper-dotActive': {
            backgroundColor: colors.secondaryRed,
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '&:hover': {
            '& > label': {
              color: palette.text.primary,
            },
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: typographies.CenturyGothic,
          fontSize: '2rem',
          fontWeight: 400,
          lineHeight: 1,
          letterSpacing: '-0.01em',
          // color: 'inherit',
          color: alpha(palette.text.primary, 0.7),
          marginBottom: '0.3em',
          '&.Mui-disabled': {
            color: 'inherit',
            opacity: '0.7 !important',
          },
          '&.Mui-focused': {
            // color: 'inherit',
            color: palette.text.primary,
          },
        },
        asterisk: {
          color: 'inherit !important',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '1.6rem',
          fontWeight: 400,
          lineHeight: 1.25,
          letterSpacing: '-0.01em',
          '& > a': {
            color: 'currentColor',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: typographies.CenturyGothic,
          fontSize: '2rem',
          lineHeight: 1.4,
          letterSpacing: '-0.01em',
          color: palette.text.primary,

          '&.Mui-disabled': {
            opacity: 0.3,
          },
        },
        input: {
          fontSize: '2rem',
          fontWeight: 400,
          lineHeight: 1.25,
          letterSpacing: '-0.01em',
          // fix chrome, safari an edge
          '&::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
          },
          '&::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
          },
          // fix for firefox
          '&[type=number]': { MozAppearance: 'textfield' },
          // fix for autofill
          '&:-webkit-autofill, &:-webkit-autofill:hover,  &:-webkit-autofill:focus,  &:-webkit-autofill:active': {
            WebkitBoxShadow: `0 0 0 30px ${palette.background.default} inset !important`,
            WebkitTextFillColor: palette.text.primary + ' !important',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          color: palette.text.primary,
        },
        underline: {
          '&.Mui-disabled': {
            '&:before': {
              borderBottomStyle: 'solid !important',
            },
          },
          '&.Mui-error:before': {
            borderBottomColor: `${palette.error.main} !important`,
          },
          '&:before': {
            borderBottomWidth: `2px !important`,
            borderBottomColor: `${palette.text.primary} !important`,
          },
          '&:after': {
            display: 'none',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          overflow: 'visible',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        root: {
          '&.survey': {
            backgroundColor: colors.white,
            '& > label': {
              color: colors.black,
            },
            '&:hover > label': {
              color: colors.black,
            },
            '& input': {
              color: colors.black,
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          height: 'auto',
          width: 'auto',
          color: palette.text.primary,
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: alpha(palette.text.primary, 0.1),
          },
        },
        colorPrimary: {
          '&.Mui-checked': {
            color: palette.text.primary,
          },
          '&.Mui-disabled': {
            color: palette.text.primary,
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: palette.text.primary,
          fontSize: '2.4rem',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: { width: 'auto', height: 'auto' },
        colorPrimary: {
          color: palette.text.primary,
          '&.Mui-checked': {
            color: palette.text.primary,
          },
        },
        colorSecondary: {
          color: palette.text.primary,
          '&.Mui-checked': {
            color: palette.text.primary,
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: colors.white,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: colors.white,
          color: colors.black,
          '&.Mui-selected': {
            backgroundColor: colors.white,
            '&:hover': {
              backgroundColor: alpha(colors.secondaryRed, 0.2),
            },
          },
          '&.Mui-focused': {
            backgroundColor: colors.white,
          },
          '&:hover': {
            backgroundColor: alpha(colors.secondaryRed, 0.2),
          },
          '&.Mui-focusVisible': {
            backgroundColor: `${alpha(colors.secondaryRed, 0.1)} !important`,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: typographies.CenturyGothic,
          fontSize: '1.2rem',
          lineHeight: 1.25,
          marginTop: 5,
          marginLeft: 0,
          marginRight: 0,
          padding: '0 0.2em',
          color: palette.error.main,
          '& > a': {
            color: 'currentColor',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontFamily: typographies.CenturyGothic,
          fontSize: '2.4rem',
          fontWeight: 700,
          lineHeight: 1,
          hyphens: 'auto',
          letterSpacing: '0.02em',
          textTransform: 'uppercase',
          textAlign: 'center',
          borderRadius: 0,
          overflow: 'hidden',
          transition: 'transform 0.1s, background-color 0.1s',
          '&:active': {
            transform: 'scale(0.98)',
          },
          '&.Mui-disabled': {
            opacity: '0.3',
          },
        },
        // label: {
        //   position: 'relative',
        //   '& > span[class*=icon]': {
        //     fontSize: '1.8rem',
        //     // fontWeight: 900,
        //     position: 'relative',
        //     top: -1,
        //   },
        // },
        containedPrimary: {
          padding: '1.3rem 1.6rem 1.1rem',
          color: palette.secondary.contrastText,
          backgroundColor: palette.secondary.main,
          '&:hover': {
            backgroundColor: darken(palette.secondary.main, 0.1),
            '@media (hover: none)': {
              backgroundColor: darken(palette.secondary.main, 0.1),
            },
          },
          '&:focus': {
            backgroundColor: darken(palette.secondary.main, 0.1),
            '@media (hover: none)': {
              backgroundColor: darken(palette.secondary.main, 0.1),
            },
          },
          '&.Mui-disabled': {
            color: palette.secondary.contrastText,
            backgroundColor: palette.secondary.main,
          },
        },
        outlinedPrimary: {
          padding: '1.1rem 1.6rem 0.9rem',
          color: palette.secondary.main,
          borderColor: palette.secondary.main,
          borderWidth: '2px',
          '&:hover': {
            backgroundColor: alpha(palette.secondary.main, 0.1),
            borderColor: palette.secondary.main,
            borderWidth: '2px',
            '@media (hover: none)': {
              backgroundColor: alpha(palette.secondary.main, 0.1),
            },
          },
          '&:focus': {
            backgroundColor: alpha(palette.secondary.main, 0.1),
            borderWidth: '2px',
          },
          '&.Mui-disabled': {
            color: palette.secondary.main,
            borderColor: palette.secondary.main,
          },
        },
        textPrimary: {
          lineHeight: 1.2,
          padding: '1.3rem 1.6rem 1.1rem',
          color: palette.secondary.main,
          textDecoration: 'underline',
          textDecorationColor: 'currentColor',
          textDecorationThickness: '2px',
          textUnderlineOffset: '4px',
          '&.Mui-disabled': {
            color: palette.secondary.main,
          },
          '&:hover': {
            color: palette.secondary.main,
            textDecoration: 'underline',
            textDecorationColor: 'currentColor',
            textDecorationThickness: '2px',
            textUnderlineOffset: '4px',
          },
          '&:focus': {
            backgroundColor: alpha(palette.secondary.main, 0.1),
            textDecoration: 'underline',
            textDecorationColor: 'currentColor',
            textDecorationThickness: '2px',
            textUnderlineOffset: '4px',
          },
        },
        containedSizeSmall: {
          padding: '8px 10px 6px',
          fontSize: 20,
          lineHeight: 1.1,
        },
        outlinedSizeSmall: {
          padding: '6px 10px 4px',
          fontSize: 20,
          lineHeight: 1.1,
        },
        textSizeSmall: {
          padding: '8px 10px 6px',
          fontSize: 20,
          lineHeight: 1.1,
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontSize: '3.2rem',
          width: '4.8rem',
          height: '4.8rem',
          borderRadius: 0,
          color: palette.primary.main,
          transition: 'transform 0.1s, background-color 0.1s',
          '&:hover': {
            backgroundColor: alpha(palette.secondary.main, 0.1),
          },
          // this seems to be sadly necessary
          '&.Mui-focusVisible': {
            backgroundColor: alpha(palette.secondary.main, 0.1),
          },
          '&:active': {
            transform: 'scale(0.98)',
          },
          '&.Mui-disabled': {
            opacity: '0.3',
          },
        },
        sizeSmall: {
          fontSize: '2.4rem',
          width: '4rem',
          height: '4rem',
        },
        colorPrimary: {
          color: palette.secondary.contrastText,
          backgroundColor: palette.secondary.main,
          '&:hover': {
            backgroundColor: darken(palette.secondary.main, 0.1),
          },
          '&.Mui-disabled': {
            color: palette.secondary.contrastText,
            backgroundColor: palette.secondary.main,
          },
        },
        colorSecondary: {
          color: palette.text.primary,
          '&:hover': {
            backgroundColor: alpha(palette.text.primary, 0.1),
          },
          '&.Mui-disabled': {
            color: palette.text.primary,
          },
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          overflow: 'visible',
          width: 'auto',
          fontSize: 'inherit',
        },
        fontSizeLarge: {
          fontSize: '3.2rem',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: '2rem',
          height: '2rem',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: { width: 'auto', height: 'auto', borderRadius: '50%' },
      },
    },
    MuiMobileStepper: {
      styleOverrides: {
        root: {
          padding: '2rem',
          justifyContent: 'center',
          backgroundColor: 'transparent',
          color: 'inherit',
        },
        dots: {
          alignItems: 'center',
        },
        dot: {
          backgroundColor: 'transparent',
          borderWidth: 1,
          borderColor: palette.text.primary,
          borderStyle: 'solid',
          margin: '0 1.5rem',
        },
        dotActive: {
          backgroundColor: palette.text.primary,
          height: '1.2rem',
          width: '1.2rem',
        },
        positionTop: {
          position: 'absolute',
          zIndex: 1,
        },
        positionBottom: {
          position: 'absolute',
          zIndex: 1,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: alpha(palette.primary.main, 0.3),
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          // @ts-ignore
          zIndex: '1500 !important',
        },
      },
    },
    // rating with numbers
    MuiFormGroup: {
      styleOverrides: {
        root: {
          '&.rating-with-numbers': {
            flexDirection: 'row',
            '& > label': {
              flex: 1,
              margin: 0,
            },
            '& [class*=MuiButton]': {
              display: 'none',
            },
            '& span': {
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '4.6rem',
              backgroundColor: colors.white,

              fontSize: '1.8rem',
              fontWeight: 600,
              lineHeight: 1,
            },
            '& [class*=checked] + span': {
              backgroundColor: palette.secondary.main,
              color: palette.secondary.contrastText,
            },
          },
        },
      },
    },
    // tables
    MuiTable: {
      styleOverrides: {
        root: {
          marginBottom: '2.4rem',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '1.6rem',
          borderBottomColor: 'currentColor',
          '&:first-child': {
            paddingLeft: 0,
          },
          '&:last-child': {
            paddingRight: 0,
          },
          '& > p': {
            marginBottom: 0,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          border: `2px solid ${palette.primary.light}`,
          borderRadius: 0,
          height: '3.2rem',

          ...typography.overline,
          backgroundColor: palette.text.secondary,
          display: 'inline-flex',
          '&:focus, &:hover': {
            backgroundColor: palette.text.primary,
          },
        },
        filled: {
          backgroundColor: palette.primary.light,
          color: palette.text.secondary,
          '&:focus, &:hover': {
            backgroundColor: darken(palette.primary.light, 0.1),
          },
        },
        outlined: {
          color: palette.primary.light,
          '&:focus, &:hover': {
            backgroundColor: `${darken(palette.text.secondary, 0.05)} !important`,
          },
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          '& button': {
            ...typography.body2,
            color: palette.text.primary,
            '&.Mui-selected': {
              backgroundColor: palette.secondary.main,
              color: palette.text.secondary,
              textTransform: 'capitalize',
              '&:hover, &:focus': {
                backgroundColor: darken(palette.secondary.main, 0.1),
                color: palette.text.secondary,
                textTransform: 'capitalize',
              },
            },
            '& .MuiFormHelperText': {
              color: palette.error.main,
            },
          },
        },
      },
    },
    MuiDatePickerToolbar: {
      styleOverrides: {
        root: {
          '& h4': {
            color: palette.text.primary,
          },
          '& button': {
            color: palette.text.primary,
          },
        },
      },
    },
    MuiDayPicker: {
      styleOverrides: {
        weekDayLabel: {
          color: palette.text.primary,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '& button': {
            color: palette.text.primary,
            textDecoration: 'none',
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '& button': {
            ...typography.body2,
            color: palette.text.primary,
          },
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          width: '92%',
        },
        labelContainer: {
          ...typography.subtitle2,
        },
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          '& span': {
            color: palette.text.primary,
          },
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        paper: {
          '& button': {
            ...typography.body2,
            color: palette.text.primary,
            '&.Mui-selected': {
              backgroundColor: palette.primary.main,
              color: palette.text.primary,
              textTransform: 'capitalize',
              '&:hover, &:focus': {
                backgroundColor: palette.primary.main,
                color: palette.text.primary,
                textTransform: 'capitalize',
              },
            },
            '&.Mui-disabled': {
              color: 'transparent',
              userSelect: 'none',
            },
            '&:disabled': {
              color: 'transparent',
              userSelect: 'none',
            },
          },
        },
      },
    },
  }
}
